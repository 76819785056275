<div class="dialog-parent">
  <ng-container *transloco="let t; read: 'calculationResultReport'">
    <ng-container *ngrxLet="bearingDesignation$ as bearingDesignation">
      <div class="flex flex-col dialog-title justify-between">
        <div class="flex flex-row content-around justify-between pb-2 pr-2">
          <button (click)="closeDialog()" mat-button>
            <mat-icon class="!mr-0">arrow_back</mat-icon>
            {{ 'general.back' | transloco }}
          </button>
        </div>
        <div class="text-title-large text-high-emphasis mb-4">
          {{
            t('dialogTitle', {
              bearingDesignation
            })
          }}
        </div>
      </div>

      <div class="h-full px-2 dialog-body">
        <ng-container
          *ngrxLet="{
            errors: reportErrors$ | ngrxPush,
            warnings:
              calculationResultFacade.calculationReportWarnings$ | ngrxPush,
            notes: calculationResultFacade.calculationReportNotes$ | ngrxPush
          } as messages"
        >
          <schaeffler-result-report
            class="flex h-full flex-row justify-between"
            [reportInputs]="
              calculationResultFacade.calculationReportInput$ | ngrxPush
            "
            [isMessageSectionExpanded]="
              !!(calculationResultFacade.getCatalogCalculationError$ | ngrxPush)
            "
            [messages]="messages"
          >
            <ng-container report-header-content>
              <ea-qualtrics-info-banner
                [bearingDesingation]="bearingDesignation$ | ngrxPush"
                class="md:mr-[255px]"
              ></ea-qualtrics-info-banner>
              <span class="block w-full h-4"></span>
            </ng-container>
            <ng-container
              report-main-content
              *ngrxLet="
                calculationParametersFacade.getCalculationTypes$
                  | ngrxPush as calculationTypes
              "
            >
              <!-- CO2 Emissions -->
              <ng-container
                *transloco="let t; read: 'calculationResultReport.co2Emissions'"
              >
                @if (
                  calculationResultFacade.isEmissionResultAvailable$ | ngrxPush
                ) {
                  <schaeffler-report-expansion-panel
                    id="emission"
                    svgIcon="co2"
                    iconClassName="!text-primary"
                    [title]="t('title')"
                    [titleTooltip]="
                      'calculationResult.emissionsTooltip' | transloco
                    "
                    [hidden]="calculationTypes?.emission.selected === false"
                    [expanded]="true"
                  >
                    <ea-calculation-result-report-emission
                      [co2Emission]="
                        calculationResultFacade.calculationReportCO2Emission$
                          | ngrxPush
                      "
                      [bearingDesignation]="bearingDesignation"
                      [downstreamError]="(downstreamErrors$ | ngrxPush)?.[0]"
                    ></ea-calculation-result-report-emission>
                  </schaeffler-report-expansion-panel>
                }
              </ng-container>

              <!-- Rating life -->
              <ng-container
                *transloco="let t; read: 'calculationResultReport.ratingLife'"
              >
                @if (
                  calculationResultFacade.isRatingLifeResultAvailable$
                    | ngrxPush
                ) {
                  <schaeffler-report-expansion-panel
                    id="ratingLife"
                    icon="animation"
                    iconClassName="!text-primary"
                    [title]="t('title')"
                    [hidden]="calculationTypes?.ratingLife.selected === false"
                    [expanded]="true"
                  >
                    @if (
                      calculationResultFacade.calculationReportRatingLife$
                        | ngrxPush;
                      as ratingLife
                    ) {
                      <ea-calculation-result-report-large-items
                        [items]="ratingLife"
                        translocoRoot="calculationResultReport.ratingLife"
                      ></ea-calculation-result-report-large-items>
                    }
                  </schaeffler-report-expansion-panel>
                }
              </ng-container>

              <!-- Frictional power loss -->
              <ng-container
                *transloco="
                  let t;
                  read: 'calculationResultReport.frictionalPowerloss'
                "
              >
                @if (
                  calculationResultFacade.isFrictionResultAvailable$ | ngrxPush
                ) {
                  <schaeffler-report-expansion-panel
                    id="frictionalPowerloss"
                    icon="compress"
                    iconClassName="!text-primary"
                    [title]="t('title')"
                    [hidden]="
                      calculationTypes?.frictionalPowerloss.selected === false
                    "
                    [expanded]="true"
                  >
                    @if (
                      calculationResultFacade.calculationReportFrictionalPowerloss$
                        | ngrxPush;
                      as frictionalPowerloss
                    ) {
                      <ea-calculation-result-report-large-items
                        [items]="frictionalPowerloss"
                        translocoRoot="calculationResultReport.frictionalPowerloss"
                      ></ea-calculation-result-report-large-items>
                    } @else {
                      <div
                        class="item-center flex content-center p-4 md:p-8 w-full border-border border-t"
                      >
                        <ea-info-banner [className]="'w-full'">
                          <div class="flex w-full flex-col">
                            <div>
                              {{ t('calculationNotAvailable.line1') }}
                            </div>
                            <div>
                              {{ t('calculationNotAvailable.line2') }}
                              <a
                                [href]="t('calculationNotAvailable.link')"
                                class="underline"
                                target="_blank"
                                >{{ t('calculationNotAvailable.linkText') }}
                                <mat-icon class="align-middle" [inline]="true"
                                  >open_in_new</mat-icon
                                >
                              </a>
                            </div>
                          </div>
                        </ea-info-banner>
                      </div>
                    }
                  </schaeffler-report-expansion-panel>
                }
              </ng-container>

              <!-- Lubrication Parameters -->
              <ng-container
                *transloco="let t; read: 'calculationResultReport.lubrication'"
              >
                @if (
                  calculationResultFacade.isLubricationResultAvailable$
                    | ngrxPush
                ) {
                  <schaeffler-report-expansion-panel
                    id="lubrication"
                    svgIcon="water_drop"
                    iconClassName="!text-primary"
                    [title]="t('title')"
                    [hidden]="calculationTypes?.lubrication.selected === false"
                    [expanded]="true"
                  >
                    @if (
                      calculationResultFacade.calculationReportLubrication$
                        | ngrxPush;
                      as ratingLife
                    ) {
                      <ea-calculation-result-report-large-items
                        [items]="ratingLife"
                        translocoRoot="calculationResultReport.lubrication"
                      ></ea-calculation-result-report-large-items>
                    }
                  </schaeffler-report-expansion-panel>
                }
              </ng-container>

              <!--- Overrolling frequencies -->
              <ng-container
                *transloco="
                  let t;
                  read: 'calculationResultReport.overrollingFrequencies'
                "
              >
                @if (
                  calculationResultFacade.isOverrollingFrequenciesAvailable$
                    | ngrxPush
                ) {
                  <schaeffler-report-expansion-panel
                    id="overrollingFrequency"
                    svgIcon="airwaves"
                    iconClassName="!text-primary"
                    [title]="t('title')"
                    [hidden]="
                      calculationTypes?.overrollingFrequency.selected === false
                    "
                    [expanded]="true"
                  >
                    @if (
                      calculationResultFacade.getOverrollingFrequencies$
                        | ngrxPush;
                      as overrollingFrequencies
                    ) {
                      <ea-calculation-result-report-large-items
                        [items]="overrollingFrequencies"
                        translocoRoot="calculationResultReport.overrollingFrequencies"
                        [firstItemLarge]="false"
                      ></ea-calculation-result-report-large-items>
                    }
                  </schaeffler-report-expansion-panel>
                }
              </ng-container>
            </ng-container>
            <ng-container report-right-sidebar>
              <!-- Right Hand Navigation -->
              <ea-calculation-result-report-selection
                [calculationResultTypeSelection]="
                  calculationResultFacade.getSelectedCalculations$ | ngrxPush
                "
                [isDownloadDisabled]="
                  (calculationResultFacade.isPDFReportAvailable$ | ngrxPush) ===
                  false
                "
                [hasDownstreamResult]="
                  !!(
                    calculationResultFacade.calculationReportCO2Emission$
                    | ngrxPush
                  )?.co2_downstream?.emission
                "
                (downloadClicked)="downloadPdfReport()"
                (calculationTypeClicked)="scrollIntoView($event)"
              >
              </ea-calculation-result-report-selection>
            </ng-container>
            <ng-container
              report-header-content
              *transloco="let t; read: 'calculationSelection'"
            >
              <!-- Mobile PDF Button --->
              <button
                mat-flat-button
                (click)="downloadPdfReport()"
                [disabled]="
                  (calculationResultFacade.isPDFReportAvailable$ | ngrxPush) ===
                  false
                "
                class="mb-3 mx-auto max-w-sm md:hidden text-center mt-4 flex w-full flex-row items-center px-4 py-2 cursor-pointer text-title-small disabled:text-medium-emphasis disabled:hover:bg-transparent disabled:cursor-auto font-semibold leading-snug"
              >
                <mat-icon class="shrink-0 aspect-square">download</mat-icon>
                <span class="block w-full flex-grow">{{ t('saveAsPDF') }}</span>
              </button>
              <span
                class="block w-auto"
                [ngClass]="{
                  'h-9': settingsFacade.isResultPreviewSticky$ | ngrxPush
                }"
              ></span>
            </ng-container>
            <ng-container report-footer-content>
              <ea-app-store-buttons
                [title]="'appStoreButtonsTitle' | transloco"
                (appStoreClick)="sendClickEvent($event)"
              ></ea-app-store-buttons>
            </ng-container>
          </schaeffler-result-report>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</div>
