import { createSelector } from '@ngrx/store';

import { DownstreamCalculationResult } from '../../models';
import { getDownstreamCalculationState } from '../../reducers';

export const getDownstreamCalculationStateResult = createSelector(
  getDownstreamCalculationState,
  (state): DownstreamCalculationResult => state?.result
);

export const isDownstreamCalculationLoading = createSelector(
  getDownstreamCalculationState,
  (state): boolean => state.isLoading
);

export const getError = createSelector(
  getDownstreamCalculationState,
  (state): string | undefined => state.errors?.[0]
);

export const isDownstreamResultAvailable = createSelector(
  getDownstreamCalculationStateResult,
  getError,
  (state, error): boolean => !error && !!state
);
