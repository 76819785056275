<ng-container *transloco="let t; read: 'sidebar'">
  <div class="space-y-4">
    <h3 class="text-title-medium text-medium-emphasis">
      {{ t('languageSettings') }}
    </h3>
    <schaeffler-language-select class="block"></schaeffler-language-select>
    <schaeffler-locale-select
      class="block pt-2 sm:pt-0"
      [availableLocales]="availableLocales"
      [defaultLocale]="defaultLocale"
    ></schaeffler-locale-select>
  </div>
</ng-container>
