/* eslint-disable ngrx/prefer-effect-callback-in-block-statement */
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { catchError, of, switchMap, takeUntil } from 'rxjs';

import { DownstreamCalculationService } from '@ea/core/services/downstream-calculation.service';
import {
  DownstreamAPIRequest,
  DownstreamOperatingConditions,
} from '@ea/core/services/downstream-calculation.service.interface';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';

import { CO2DownstreamCalculationActions } from '../../actions';
import {
  CalculationParametersFacade,
  ProductSelectionFacade,
} from '../../facades';
import { CalculationParametersOperationConditions } from '../../models';

@Injectable()
export class DownstreamCalculationEffect {
  public fetchDownstreamCalculation$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CO2DownstreamCalculationActions.fetchDownstreamCalculation),
      concatLatestFrom(() => [
        this.productSelectionFacade.bearingDesignation$,
        this.calculationParametersFacade.getLoadcases$,
        this.calculationParametersFacade.operationConditions$,
        this.calculationParametersFacade.getLoadcaseCount$,
      ]),
      switchMap(
        ([
          _action,
          designation,
          loadcases,
          operatingConditions,
          numberLoadcases,
        ]) => {
          const lubrication = operatingConditions.lubrication;

          const greaseType = this.getGreaseType(lubrication);
          const lubricationMethod = this.getLubricationMethod(lubrication);
          const viscosityDefinition =
            this.convertDefinitionOfViscosity(lubrication);
          const isoVgClass = this.getIsoVgClass(
            viscosityDefinition,
            lubrication
          );

          const { energySource } = operatingConditions;
          const emissionFactorCalculation =
            this.getEmissionFactor(energySource);

          const calcRequest: DownstreamAPIRequest = {
            operatingConditions: {
              electricEmissionFactor: energySource.electric?.electricityRegion,
              fossilEmissionFactor: energySource.fossil?.fossilOrigin,
              emissionFactor: emissionFactorCalculation,
              operatingTimeInHours: operatingConditions.time,
              temperature: operatingConditions.ambientTemperature,
              ny40: lubrication.oilBath.viscosity.ny40,
              ny100: lubrication.oilBath.viscosity.ny100,
              lubricationMethod,
              isoVgClassCalculated: undefined, // seems like enabled only with viscosityDefintion === 'LB_CALCULATE_VISCOSITIES' which is not supported
              greaseType,
              viscosityDefinition,
              isoVgClass,
            },
            loadcases: loadcases.flatMap((lc) => {
              const conditions = operatingConditions.loadCaseData[lc.index];
              const { rotation, operatingTime, operatingTemperature, load } =
                conditions;

              return {
                designation: lc.loadCaseName || '',
                speed: rotation.rotationalSpeed || 0,
                timePortion:
                  operatingTime && numberLoadcases > 1 ? operatingTime : 100,
                axialLoad: load.axialLoad || 0,
                radialLoad: load.radialLoad || 0,
                movementType: rotation.typeOfMotion,
                operatingTemperature,
              };
            }),
          };

          console.log('calcRequest', calcRequest);

          return this.downstreamService
            .getDownstreamCalculation(designation, calcRequest)
            .pipe(
              takeUntil(
                this.actions$.pipe(
                  ofType(
                    CO2DownstreamCalculationActions.fetchDownstreamCalculation
                  )
                )
              ),
              switchMap((result) => {
                console.log('result', result);

                return of(
                  CO2DownstreamCalculationActions.setDownstreamCalculationResult(
                    {
                      result,
                    }
                  )
                );
              }),
              catchError((error: HttpErrorResponse) => {
                console.log('Error from downstrema calculation api', error);

                return of(
                  CO2DownstreamCalculationActions.setCalculationFailure({
                    error: error.error.title,
                  })
                );
              })
            );
        }
      )
    )
  );

  constructor(
    private readonly downstreamService: DownstreamCalculationService,
    private readonly actions$: Actions,
    private readonly productSelectionFacade: ProductSelectionFacade,
    private readonly calculationParametersFacade: CalculationParametersFacade
  ) {}

  private getEmissionFactor(
    energySource: CalculationParametersOperationConditions['energySource']
  ): DownstreamOperatingConditions['emissionFactor'] {
    return energySource.type === 'electric'
      ? 'LB_ELECTRIC_ENERGY'
      : 'LB_FOSSIL_ENERGY';
  }

  private getGreaseType(
    lubrication: CalculationParametersOperationConditions['lubrication']
  ): DownstreamOperatingConditions['greaseType'] | undefined {
    const GREASE_SELECTION = 'grease';
    const TYPE_OF_GREASE_SELECTION = 'typeOfGrease';

    if (lubrication.lubricationSelection !== GREASE_SELECTION) {
      return undefined;
    }

    if (lubrication.grease.selection !== TYPE_OF_GREASE_SELECTION) {
      return undefined;
    }

    const greaseType = lubrication.grease.typeOfGrease
      .typeOfGrease as DownstreamOperatingConditions['greaseType'];

    return greaseType;
  }

  private getLubricationMethod(
    lubrication: CalculationParametersOperationConditions['lubrication']
  ): DownstreamOperatingConditions['lubricationMethod'] | undefined {
    const { lubricationSelection } = lubrication;
    switch (lubricationSelection) {
      case 'grease':
        return 'LB_GREASE_LUBRICATION';
      case 'oilBath':
        return 'LB_OIL_BATH_LUBRICATION';
      case 'oilMist':
        return 'LB_OIL_MIST_LUBRICATION';
      case 'recirculatingOil':
        return 'LB_RECIRCULATING_OIL_LUBRICATION';
      default:
        return undefined;
    }
  }

  private getIsoVgClass(
    viscosityDefintion: DownstreamOperatingConditions['viscosityDefinition'],
    lubricationConditions: CalculationParametersOperationConditions['lubrication']
  ): DownstreamOperatingConditions['isoVgClass'] {
    if (viscosityDefintion !== 'LB_ISO_VG_CLASS') {
      return undefined;
    }

    return `LB_ISO_VG_${
      lubricationConditions[lubricationConditions.lubricationSelection]
        .isoVgClass.isoVgClass
    }`;
  }

  private convertDefinitionOfViscosity(
    lubricationConditions: CalculationParametersOperationConditions['lubrication']
  ): DownstreamOperatingConditions['viscosityDefinition'] {
    const { selection } =
      lubricationConditions[lubricationConditions.lubricationSelection];
    // | 'LB_CALCULATE_VISCOSITIES' not currently used?

    switch (selection) {
      case 'isoVgClass':
        return 'LB_ISO_VG_CLASS';
      case 'typeOfGrease':
        return 'LB_ARCANOL_GREASE';
      case 'viscosity':
        return 'LB_ENTER_VISCOSITIES';
      default:
        return undefined;
    }
  }
}
