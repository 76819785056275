<div
  *transloco="let t; read: translocoRoot"
  class="grid grid-cols-2 gap-0 px-4 md:px-8 py-4 border-border border-t"
>
  @for (
    item of items;
    track item;
    let index = $index;
    let isFirst = $first;
    let length = $count;
    let isOdd = $odd;
    let isEven = $even;
    let isLast = $last
  ) {
    <div
      class="flex flex-col justify-start border-border md:border-b-0 border-b md:mx-2 py-4 md:py-2"
      [ngClass]="{
        'md:mr-4 pl-4 md:pl-0': isEven,
        'md:border-r':
          length > 1 && isEven && (item.loadcaseValues?.length || 0) <= 4,
        'border-r-0': length > 1 && isEven && isLast,
        'md:mr-4 pr-4 md:pr-0': isOdd,
        'md:border-r-0':
          (length > 1 && isOdd && (item.loadcaseValues?.length || 0) <= 4) ||
          (item.loadcaseValues?.length || 0) > 4,
        'border-r': length > 1 && isOdd,
        'col-span-2 md:col-span-1 !pl-0': isFirst,
        'md:col-span-2': (item.loadcaseValues?.length || 0) > 4,
        'lg:border-r-0': (item.loadcaseValues?.length || 0) > 4 || isLast,
        'border-b-0': isLast || (length >= 2 && index === length - 2 && isOdd)
      }"
    >
      @if (item.short) {
        <div class="mb-4">
          @if (!item.warning) {
            <ea-tag
              [value]="item.short"
              styleClass="text-info bg-info border-info"
            ></ea-tag>
          }
          @if (item.warning) {
            <ea-tag
              [value]="item.short"
              styleClass="text-warning bg-warning border-warning"
            ></ea-tag>
          }
        </div>
      }
      <div
        class="grid sm:grid-cols-2 grid-cols-1"
        [ngClass]="{
          'lg:grid-cols-4': item.loadcaseValues?.length < 5,
          'lg:grid-cols-5': item.loadcaseValues?.length === 5,
          'lg:grid-cols-6': item.loadcaseValues?.length > 5
        }"
      >
        @if ((item.loadcaseValues?.length || 0) === 0) {
          @if (item.value !== undefined) {
            <div class="flex flex-row items-end justify-start">
              <div
                class="text-high-emphasis whitespace-nowrap"
                [ngClass]="{
                  'text-title-large sm:text-display-small leading-9':
                    isFirst && firstItemLarge,
                  'text-body-large sm:text-title-large':
                    !isFirst || !firstItemLarge
                }"
              >
                {{ (item.value | meaningfulRound) || '-' }}
              </div>
              <div class="text-body-medium text-high-emphasis pl-1">
                {{ item.unit }}
              </div>
            </div>
          }
          @if (item.warning) {
            <div class="flex flex-row items-center justify-start">
              <div class="pr-1 text-icon-warning">
                <mat-icon>info_outline</mat-icon>
              </div>
              <div class="text-high-emphasis text-body-small">
                {{ t(item.warning) }}
              </div>
            </div>
          }
        } @else {
          @for (
            loadcaseValue of item.loadcaseValues;
            track loadcaseValue;
            let isFirstValue = $first;
            let isLastValue = $last;
            let isEvenValue = $even;
            let isOddValue = $odd;
            let valueIndex = $index;
            let valuesLength = $count
          ) {
            <div
              class="md:px-4 sm:pl-4 mb-2 lg:mb-0"
              [ngClass]="{
                'lg:!pl-0 md:pl-0 pl-0': isFirstValue,
                'md:pl-0 pl-0': isEvenValue,
                'md:pl-2 px-2': isEvenValue && valuesLength > 1,
                'sm:border-r sm:border-border': !isLastValue,
                'sm:border-r-0 border-r-0': isOddValue,
                'md:border-r-0 lg:border-r lg:border-border':
                  isOddValue && !isLastValue,
                'md:px-2': valuesLength > 1,
                'md:!pl-0 sm:!pl-0': valuesLength > 1 && isEvenValue,
                'lg:!pl-4':
                  valuesLength > 1 &&
                  !isFirstValue &&
                  !((valueIndex + 1) % 7 === 0),
                'lg:border-r-0': (valueIndex + 1) % 6 === 0,
                'lg:pl-0': (valueIndex + 1) % 7 === 0
              }"
            >
              @if (valuesLength > 1) {
                <div
                  class="text-body-small !text-[8px] text-medium-emphasis mb-2"
                >
                  {{ loadcaseValue.loadcaseName }}
                </div>
              }
              @if (loadcaseValue.value !== undefined) {
                <div class="flex flex-row items-end justify-start">
                  <div
                    class="text-high-emphasis whitespace-nowrap"
                    [ngClass]="{
                      'text-title-large sm:text-display-small leading-9':
                        isFirst && firstItemLarge,
                      'text-body-large sm:text-title-large':
                        !isFirst || !firstItemLarge
                    }"
                  >
                    {{ (loadcaseValue.value | meaningfulRound) || '-' }}
                  </div>
                  <div
                    class="text-body-medium text-high-emphasis pl-1 whitespace-nowrap"
                  >
                    {{ item.unit }}
                  </div>
                </div>
              }
              @if (item.warning) {
                <div class="flex flex-row items-center justify-start">
                  <div class="pr-1 text-icon-warning">
                    <mat-icon>info_outline</mat-icon>
                  </div>
                  <div class="text-high-emphasis text-body-small">
                    {{ t(item.warning) }}
                  </div>
                </div>
              }
            </div>
          }
        }
      </div>
      <div class="text-body-small text-medium-emphasis lg:mt-4 mt-auto">
        {{ t(item.title) }}
        @if (item.titleTooltip) {
          <ea-info-button
            class="inline-flex align-bottom"
            [inline]="true"
            [tooltip]="item.titleTooltip"
          ></ea-info-button>
        }
      </div>
    </div>
    @if ((isOdd || item.loadcaseValues?.length > 4) && !isLast) {
      <div
        class="border-t border-border my-4 h-[1px] col-span-2 md:block hidden"
      ></div>
    }
  }
</div>
