<ng-container *transloco="let t; read: 'calculationResult'">
  @if (_item) {
    <div
      class="flex flex-shrink-0 flex-col items-center justify-between md:justify-center disabled-border border-border md:border-0 md:w-auto pt-1 sm:pt-2 md:h-auto h-full md:bg-transparent rounded-lg"
      [ngClass]="styleClass"
    >
      <div class="flex flex-shrink-0 flex-row items-center justify-center px-2">
        <mat-icon
          [svgIcon]="_item.svgIcon"
          class="mr-1 flex-shrink-0 text-primary"
        >
          {{ _item.icon }}
        </mat-icon>
        <div
          class="text-body-small text-medium-emphasis inline-flex flex-shrink-0 font-medium"
        >
          {{ t(_item.title) }}
        </div>
        @if (_item.titleTooltip) {
          <ea-info-button
            class="ml-1 h-4 text-body-small text-medium-emphasis font-medium"
            [inline]="true"
            [tooltip]="t(_item.titleTooltip)"
          ></ea-info-button>
        }
      </div>
      <div class="flex flex-row">
        @for (
          value of _item.values;
          track value;
          let last = $last;
          let first = $first
        ) {
          <div
            class="flex flex-col md:pt-2 px-2 sm:px-4"
            [class.items-center]="isSingleItem"
          >
            <div
              class="text-body-small text-medium-emphasis mb-2"
              [class.text-center]="isSingleItem"
            >
              {{ value.title && t(value.title) }}
              @if (value.displayNewBadge) {
                <mat-icon
                  class="!text-primary !h-4 align-bottom"
                  svgIcon="new_badge"
                  >new_badge</mat-icon
                >
              }
              @if (value.titleTooltip) {
                <ea-info-button
                  class="inline-flex align-bottom"
                  [inline]="true"
                  [tooltip]="t(value.titleTooltip)"
                ></ea-info-button>
              }
            </div>
            @if (!value.isLoading) {
              <div class="flex flex-row items-end justify-start">
                @if (!value.calculationError && !value.calculationWarning) {
                  <div class="text-body-medium text-high-emphasis">
                    {{ (value.value | meaningfulRound) || '-' }}
                  </div>

                  @if (
                    value.title === 'usage' &&
                    value?.additionalData?.operatingTimeInHours &&
                    value?.additionalData?.operatingTimeInHours !==
                      yearlyEmissionUsageInHours
                  ) {
                    <div class="text-body-medium text-high-emphasis pl-1">
                      {{
                        t('hourlyEmssionInfo', {
                          unit: value.unit,
                          hours: value.additionalData.operatingTimeInHours
                        })
                      }}
                    </div>
                  } @else {
                    @if (value.unit) {
                      <div class="text-body-medium text-high-emphasis pl-1">
                        {{ value.unit }}
                      </div>
                    }
                  }

                  @if (value.title === 'production') {
                    <ea-calculation-result-preview-emissions-tooltip>
                    </ea-calculation-result-preview-emissions-tooltip>
                  }
                } @else {
                  @if (value.calculationError) {
                    <ng-container [ngTemplateOutlet]="error"> </ng-container>
                  } @else {
                    <div class="flex items-center">
                      <mat-icon
                        class="mr-2 align-middle flex-shrink-0 text-icon-info"
                        >info_outline
                      </mat-icon>
                      <div
                        class="text-body-small text-medium-emphasis md:max-w-[12rem] md:text-left text-center"
                      >
                        {{ t(value.calculationWarning) }}
                      </div>
                    </div>
                  }
                }
                <ng-template #error>
                  <div
                    class="flex flex-shrink-0 flex-grow"
                    [matTooltip]="value.calculationError"
                  >
                    <mat-icon class="!text-body-medium mx-auto text-error"
                      >warning</mat-icon
                    >
                  </div>
                </ng-template>
                <ng-template #warning>
                  <div class="flex items-center">
                    <mat-icon
                      class="mr-2 align-middle flex-shrink-0 text-icon-info"
                      >info_outline
                    </mat-icon>
                    <div
                      class="text-body-small text-medium-emphasis md:max-w-[12rem] md:text-left text-center"
                    >
                      {{ t(value.calculationWarning) }}
                    </div>
                  </div>
                </ng-template>
              </div>
            } @else {
              <div class="flex items-center justify-center">
                <mat-spinner diameter="16"></mat-spinner>
              </div>
            }

            @if (value.valueLoadcaseName && value.valueLoadcaseName !== '') {
              <div class="text-body-small text-medium-emphasis py-2">
                {{ value.valueLoadcaseName }}
              </div>
            } @else if (
              value?.additionalData?.operatingTimeInHours &&
              value?.additionalData?.operatingTimeInHours ===
                yearlyEmissionUsageInHours
            ) {
              <div class="text-body-small text-medium-emphasis py-2">
                {{ t('yearlyEmissionInfo') }}
              </div>
            }

            @if (_item.loadcaseName && _item.loadcaseName !== '') {
              <div class="text-body-small text-medium-emphasis py-2">
                {{ _item.loadcaseName }}
              </div>
            }
          </div>
          @if (!last) {
            <mat-divider [vertical]="true"></mat-divider>
          }
        }
      </div>
    </div>
  }
</ng-container>
