import { combineLatest, Observable } from 'rxjs';

import {
  ElectricityRegionOption,
  FossilOriginOption,
} from '@ea/core/services/calculation-parameters';
import { CalculationParametersEnergySource } from '@ea/core/store/models';
import {
  createTranslationObservables,
  TranslationItem,
} from '@ea/shared/helper';
import { TranslocoService } from '@jsverse/transloco';

export const getElectricityRegionOptions = (
  translocoService: TranslocoService
): Observable<ElectricityRegionOption[]> => {
  const baseKey = 'operationConditions.energySource.electricityRegionOption.';

  const items: TranslationItem<
    CalculationParametersEnergySource['electric']['electricityRegion']
  >[] = [
    {
      key: `argentina`,
      value: 'LB_ARGENTINA' as const,
    },
    {
      key: `australia`,
      value: 'LB_AUSTRALIA' as const,
    },
    {
      key: `brazil`,
      value: 'LB_BRAZIL' as const,
    },
    {
      key: `canada`,
      value: 'LB_CANADA' as const,
    },
    {
      key: `china`,
      value: 'LB_CHINA' as const,
    },
    {
      key: `europeanUnion`,
      value: 'LB_EUROPEAN_UNION' as const,
    },
    {
      key: `france`,
      value: 'LB_FRANCE' as const,
    },
    {
      key: `germany`,
      value: 'LB_GERMANY' as const,
    },
    {
      key: `greatBritain`,
      value: 'LB_GREAT_BRITAIN' as const,
    },
    {
      key: `india`,
      value: 'LB_INDIA' as const,
    },
    {
      key: `indonesia`,
      value: 'LB_INDONESIA' as const,
    },
    {
      key: `italy`,
      value: 'LB_ITALY' as const,
    },
    {
      key: `japan`,
      value: 'LB_JAPAN' as const,
    },
    {
      key: `mexico`,
      value: 'LB_MEXICO' as const,
    },
    {
      key: `russianFederation`,
      value: 'LB_RUSSIAN_FEDERATION' as const,
    },
    {
      key: `southAfrica`,
      value: 'LB_SOUTH_AFRICA' as const,
    },
    {
      key: `southKorea`,
      value: 'LB_SOUTH_KOREA' as const,
    },
    {
      key: `turkey`,
      value: 'LB_TURKEY' as const,
    },
    {
      key: `usa`,
      value: 'LB_USA' as const,
    },
  ];

  const observables = createTranslationObservables(
    translocoService,
    baseKey,
    items
  );

  return combineLatest(observables);
};

export const getFossilOriginOptions = (
  translocoService: TranslocoService
): Observable<FossilOriginOption[]> => {
  const baseKey = 'operationConditions.energySource.fossilOriginOptions.';

  const items: TranslationItem<
    CalculationParametersEnergySource['fossil']['fossilOrigin']
  >[] = [
    {
      key: `gasoline`,
      value: 'LB_GASOLINE_FOSSIL' as const,
    },

    {
      key: `diesel`,
      value: 'LB_DIESEL_FOSSIL' as const,
    },
    {
      key: `e10`,
      value: 'LB_GASOLINE_E10' as const,
    },
    {
      key: `diesel87`,
      value: 'LB_DIESEL_B7' as const,
    },
    {
      key: `lpg`,
      value: 'LB_LPG' as const,
    },
    {
      key: `cng`,
      value: 'LB_CNG' as const,
    },
    {
      key: `cngBiomethane`,
      value: 'LB_CNG_BIOMETHANE' as const,
    },
  ];

  const observables = createTranslationObservables(
    translocoService,
    baseKey,
    items
  );

  return combineLatest(observables);
};
