export const downstreamErrors: { [key: string]: string } = {
  MODEL_NOT_EXISTING: 'modelNotExisting',
  MODEL_LUBRICATION_NOT_SUPPORTED: 'modelLubricationNotSupported',
  SUM_OF_TIME_PORTIONS_BELOW_100_PERCENT: 'sumOfTimePortionsBelow_100Percent',
  SUM_OF_TIME_PORTIONS_EXCEEDS_100_PERCENT:
    'sumOfTimePortionsExceeds_100Percent',
  INVALID_MOVEMENT_TYPE: 'invalidMovementType',
  OPERATING_TEMPERATURE_BELOW_LIMIT: 'operatingTemperatureBelowLimit',
  OPERATING_TEMPERATURE_EXCEEDS_LIMIT: 'operatingTemperatureExceedsLimit',
  INVALID_VISCOSITY_DEFINITION: 'invalidViscosityDefinition',
  INVALID_LUBRICATION_METHOD: 'invalidLubricationMethod',
  INVALID_GREASE_SELECTION: 'invalidGreaseSelection',
  INVALID_VISCOSITY_CLASS: 'invalidViscosityClass',
  NY100_EXCEEDS_NY40: 'ny100ExceedsNy40',
  NY40_BELOW_LIMIT: 'ny40BelowLimit',
  NY40_EXCEEDS_LIMIT: 'ny40ExceedsLimit',
  NY100_BELOW_LIMIT: 'ny100BelowLimit',
  NY100_EXCEEDS_LIMIT: 'ny100ExceedsLimit',
  SPEED_BELOW_THRESHOLD: 'speedBelowThreshold',
  SPEED_EXCEEDS_LIMITING_SPEED: 'speedExceedsLimitingSpeed',
  P0_BELOW_C0_MIN: 'p0BelowC0Min',
  P0_EXCEEDS_C0: 'p0ExceedsC0',
  LOAD_ANGLE_BELOW_NOMINAL_CONTACT_ANGLE: 'loadAngleBelowNominalContactAngle',
  OPERATING_VISCOSITY_BELOW_LIMIT: 'operatingViscosityBelowLimit',
  OPERATING_VISCOSITY_EXCEEDS_LIMIT: 'operatingViscosityExceedsLimit',
  VISCOSITY_RATIO_BELOW_LIMIT: 'viscosityRatioBelowLimit',
  VISCOSITY_RATIO_EXCEEDS_LIMIT: 'viscosityRatioExceedsLimit',
  RADIAL_LOAD_BELOW_LIMIT: 'radialLoadBelowLimit',
  RADIAL_LOAD_NOT_SUPPORTED: 'radialLoadNotSupported',
  AXIAL_LOAD_BELOW_LIMIT: 'axialLoadBelowLimit',
  AXIAL_LOAD_BELOW_MINIMUM: 'axialLoadBelowMinimum',
  AXIAL_LOAD_EXCEEDS_LIMIT: 'axialLoadExceedsLimit',
  AXIAL_LOAD_NOT_SUPPORTED: 'axialLoadNotSupported',
  LOAD_RATIO_TOO_SMALL: 'loadRatioTooSmall',
  LOAD_RATIO_TOO_LARGE: 'loadRatioTooLarge',
  REFERENCE_VISCOSITY_IS_ZERO: 'referenceViscosityIsZero',
  MEAN_DIAMETER_IS_ZERO: 'meanDiameterIsZero',
  UNSUPPORTED_EMISSION_FACTOR: 'unsupportedEmissionFactor',
};
