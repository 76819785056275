@if (tooltip) {
  <mat-icon
    class="cursor-help text-primary"
    [ngClass]="{ 'text-primary': !inline }"
    [inline]="inline"
    #tooltipRef="matTooltip"
    [matTooltip]="tooltip"
    [matTooltipPosition]="tooltipPosition"
    [matTooltipClass]="tooltipClass"
    (click)="tooltipRef.toggle()"
  >
    info_outline
  </mat-icon>
}
