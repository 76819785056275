<div
  *transloco="let t; read: 'calculationSelection'"
  class="flex flex-col justify-between h-auto self-start md:top-4 md:sticky"
  [ngClass]="{
    'items-center': !isInDialog,
    'items-start': isInDialog,
    'p-4': isInDialog
  }"
>
  <div class="flex flex-col" [ngClass]="{ 'items-center': !isInDialog }">
    @if (
      isInDialog && getCalculationTypesGlobalSelection$ | ngrxPush;
      as selectionState
    ) {
      <div>
        <button
          mat-button
          (click)="selectAllChanged(true)"
          [disabled]="
            selectionState?.selectAll && !selectionState?.indeterminate
          "
        >
          {{ t('selectAll') }}
        </button>
      </div>
    }
    @if (!isInDialog) {
      <div class="text-title-medium text-medium-emphasis mb-8">
        {{ t('title') }}
      </div>
    }
    <section
      class="flex flex-col"
      [ngClass]="{ 'w-56': !isInDialog, 'max-w-2xl': isInDialog }"
    >
      @if (!isInDialog) {
        @if (
          getCalculationTypesGlobalSelection$ | ngrxPush;
          as selectionState
        ) {
          <div class="mb-2">
            <mat-checkbox
              [disableRipple]="true"
              class="hover:bg-secondary-legacy-100 hover:text-primary text-medium-emphasis px-4 py-2 hover:rounded"
              (click)="
                selectAllChanged(
                  !selectionState.selectAll || selectionState.indeterminate
                )
              "
              [indeterminate]="selectionState.indeterminate"
              [checked]="selectionState.selectAll"
            >
              <div class="flex">
                <mat-icon class="mr-2 flex-shrink-0">calculate</mat-icon>
                <div class="text-title-medium text-medium-emphasis">
                  {{ t('calculations') }}
                </div>
              </div>
            </mat-checkbox>
          </div>
        }
        <mat-divider class="mb-6"></mat-divider>
      }
      @if (calculationTypesConfig$ | ngrxPush; as calculationTypesConfig) {
        <div class="flex">
          <ul>
            @for (config of calculationTypesConfig; track config) {
              <li
                class="md:mt-4 mt-2"
                [matTooltip]="t('typeUnavailable')"
                [matTooltipDisabled]="!config.disabled"
                [matTooltipPosition]="isInDialog ? 'below' : 'left'"
                #tooltipRef="matTooltip"
              >
                <mat-checkbox
                  [disableRipple]="true"
                  class="px-4 py-2 hover:rounded w-full"
                  [ngClass]="{
                    'text-low-emphasis cursor-help': config.disabled,
                    'hover:bg-secondary-legacy-100 hover:text-primary':
                      !config.disabled,
                    'text-high-emphasis': config.selected,
                    'text-medium-emphasis': !config.selected
                  }"
                  [disabled]="config.disabled"
                  [checked]="config.selected"
                  (click)="
                    !config.disabled &&
                      selectionChanged(
                        !config.selected,
                        config,
                        calculationTypesConfig
                      )
                  "
                >
                  <div class="flex flex-row items-center">
                    <mat-icon
                      class="mr-2 flex-shrink-0"
                      [svgIcon]="config.svgIcon"
                      >{{ config.icon }}</mat-icon
                    >
                    <div class="text-title-small whitespace-normal">
                      {{ t(config.label) }}
                    </div>
                  </div>
                </mat-checkbox>
              </li>
            }
          </ul>
        </div>
      }
    </section>
  </div>

  @if (!isInDialog) {
    <div class="mt-16 md:mt-8 md:mb-auto">
      <button (click)="onShowBasicFrequenciesDialogClick()" mat-button>
        {{ 'basicFrequencies.showBasicFrequencies' | transloco }}
      </button>
      <div
        class="py-4 mt-4 mx-auto border-border border-t text-medium-emphasis text-center text-body-medium"
      >
        {{ 'general.poweredBy' | transloco: { name: 'Bearinx 2023.2' } }}
      </div>
    </div>
  } @else {
    <div class="flex flex-row items-center w-full mt-4">
      <button mat-flat-button class="w-full" (click)="dialogRef.close()">
        {{ 'ok' | transloco }}
      </button>
    </div>
  }
</div>
