<ng-container *transloco="let t; read: 'basicFrequencies'">
  <div mat-dialog-title class="px-0">
    <div class="flex flex-row content-around justify-between pb-2 pl-4 pr-2">
      <div class="text-title-large text-high-emphasis my-auto">
        {{
          t('dialogTitle', {
            bearingDesignation: bearingDesignation$ | ngrxPush
          })
        }}
      </div>
      <div class="flex flex-row">
        <button mat-icon-button (click)="closeDialog()">
          <mat-icon class="text-medium-emphasis">close</mat-icon>
        </button>
      </div>
    </div>
  </div>

  <mat-dialog-content class="!px-4 pb-4">
    @if (basicFrequencies$ | ngrxPush; as basicFrequencies) {
      <div class="border-border rounded border overflow-auto min-w-[400px]">
        <div
          class="border-border text-title-medium border-b md:px-4 md:py-2 p-2"
        >
          {{ basicFrequencies.title }}
        </div>
        <div class="md:px-8 p-0 md:py-4">
          <table
            mat-table
            [dataSource]="basicFrequencies.rows"
            class="md:w-full"
          >
            <ng-container matColumnDef="name">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let item">
                {{ item.title }}
              </td>
            </ng-container>
            <ng-container matColumnDef="abbreviation">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let item" class="!px-4">
                {{ item.abbreviation }}
              </td>
            </ng-container>
            <ng-container matColumnDef="value">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let item">
                {{ item.value | number: '1.2-2' }}
              </td>
            </ng-container>
            <tr
              mat-row
              [class.bg-hover-overlay]="i % 2 === 1"
              *matRowDef="let row; let i = index; columns: columnsToDisplay"
            ></tr>
          </table>
        </div>
      </div>
    } @else {
      <div class="item-center flex content-center p-8">
        <div class="ml-auto mr-auto">
          <mat-spinner diameter="20"></mat-spinner>
        </div>
      </div>
    }
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button (click)="saveAsPdf()">
      <mat-icon>download</mat-icon>
      {{ t('saveAsPdf') }}
    </button>
  </mat-dialog-actions>
</ng-container>
