import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@ea/environments/environment';

import {
  DownstreamAPIRequest,
  DownstreamAPIResponse,
} from './downstream-calculation.service.interface';

@Injectable({ providedIn: 'root' })
export class DownstreamCalculationService {
  constructor(private readonly httpService: HttpClient) {}

  getDownstreamCalculation(
    designation: string,
    calculationParameters: DownstreamAPIRequest
  ) {
    return this.httpService.post<DownstreamAPIResponse>(
      `${environment.downstreamCo2ApiUrl}co2ecalculation/calculate?designation=${designation}`,
      calculationParameters
    );
  }
}
