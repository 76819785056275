<mat-radio-group [formControl]="control">
  <mat-radio-button
    [value]="value"
    (change)="onChange()"
    [class.suboption]="isSubOption"
    [disabled]="disabled"
  >
    {{ label }}
  </mat-radio-button>
</mat-radio-group>
