@if (!isNativeMobile) {
  <div class="flex flex-col items-center mt-2">
    @if (title) {
      <p class="text-body-2 text-medium-emphasis">{{ title }}</p>
    }
    <div
      class="container mx-auto grid grid-cols-2 md:grid-cols-4 gap-2 md:gap-4 md:max-w-screen-md mt-4 mb-4 md:mb-0"
    >
      <div class="flex flex-row justify-center md:justify-end md:col-start-2">
        <a
          href="https://apps.apple.com/us/app/medias-easycalc/id6702015194"
          target="_blank"
          (click)="onAppStoreClick('appStore')"
        >
          <img
            [src]="iOSStore"
            class="w-40 md:w-auto md:h-8 h-12"
            alt="Download on Apple App Store badge"
          />
        </a>
      </div>
      <div
        class="flex flex-row justify-center md:justify-start col-start md:col-start-3"
      >
        <a
          href="https://play.google.com/store/apps/details?id=com.schaeffler.engineering.easycalc"
          target="_blank"
          (click)="onAppStoreClick('play')"
        >
          <img
            [src]="playStore"
            class="w-40 md:w-auto md:h-8 h-12"
            alt="Download on Google Play Store badge"
          />
        </a>
      </div>
    </div>
  </div>
}
