<ng-container
  *transloco="let t; read: 'calculationResultReport.calculationDisclaimer'"
>
  <div mat-dialog-title>
    <div class="flex flex-row content-around justify-between pb-2 pr-2">
      <div class="text-title-large text-high-emphasis my-auto">
        <p>{{ t('title') }}</p>
      </div>
      <button
        mat-icon-button
        (click)="closeDialog()"
        class="flex flex-row items-center"
      >
        <mat-icon class="text-medium-emphasis">close</mat-icon>
      </button>
    </div>
  </div>
  <div mat-dialog-title></div>
  <mat-dialog-content>
    <div [innerHTML]="disclaimerContent$ | ngrxPush"></div>
  </mat-dialog-content>
</ng-container>
