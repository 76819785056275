import { DownstreamCalculationLoadcaseResult } from '@ea/core/services/downstream-calculation.service.interface';
import { createReducer, on } from '@ngrx/store';

import { CO2DownstreamCalculationActions } from '../../actions';
import { DownstreamCalculationState, LoadCaseEmission } from '../../models';

export const initialState: DownstreamCalculationState = {
  isLoading: false,
  errors: [],
  warnings: [],
  notes: [],
};

const convertFromGramsToKilograms = (value: number): number => value / 1000;

const processLoadcaseResultData = (
  loadcaseResultData: DownstreamCalculationLoadcaseResult[]
): { [key: string]: LoadCaseEmission } => {
  const loadcases: { [key: string]: LoadCaseEmission } = {};

  for (const lcr of loadcaseResultData) {
    loadcases[`${lcr.designation}`] = {
      co2Emissions: lcr.co2Emissions
        ? convertFromGramsToKilograms(lcr.co2Emissions)
        : lcr.co2Emissions,
      co2EmissionsUnit: 'kg',
      operatingTimeInHours: lcr.operatingTimeInHours,
      totalFrictionalPowerLoss: lcr.frictionalPowerLoss,
      totalFrictionalTorque: lcr.frictionalTorque,
    };
  }

  return loadcases;
};

export const co2downstreamCalculationReducer = createReducer(
  initialState,
  on(
    CO2DownstreamCalculationActions.resetDownstreamCalculation,
    (_state): DownstreamCalculationState => initialState
  ),
  on(
    CO2DownstreamCalculationActions.fetchDownstreamCalculation,
    (state): DownstreamCalculationState => ({
      ...state,
      isLoading: true,
    })
  ),
  on(
    CO2DownstreamCalculationActions.setDownstreamCalculationResult,
    (state, { result }): DownstreamCalculationState => {
      if (result.errors.length > 0) {
        return {
          ...state,
          errors: result.errors.flatMap((error) => error.message),
        };
      }

      const loadcases = processLoadcaseResultData(result.loadcaseResults);

      return {
        ...state,
        isLoading: false,
        result: {
          co2Emissions: result.idcO_CO2_EMISSIONS
            ? convertFromGramsToKilograms(result.idcO_CO2_EMISSIONS)
            : result.idcO_CO2_EMISSIONS,
          loadcaseEmissions: loadcases,
        },
      };
    }
  ),
  on(
    CO2DownstreamCalculationActions.setCalculationFailure,
    (state, { error }): DownstreamCalculationState => ({
      ...state,
      result: undefined,
      isLoading: false,
      errors: [error],
    })
  )
);
