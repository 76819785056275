import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';

import { CalculationResultPreviewEmissionsTooltipComponent } from '@ea/calculation/calculation-result-preview-emissions-tooltip/calculation-result-preview-emissions-tooltip.component';
import { CalculationResultPreviewItem } from '@ea/core/store/models';
import { InfoButtonComponent } from '@ea/shared/info-button/info-button.component';
import { MeaningfulRoundPipe } from '@ea/shared/pipes/meaningful-round.pipe';

import { SharedTranslocoModule } from '@schaeffler/transloco';

@Component({
  selector: 'ea-co2-emission-preview-item',
  templateUrl: './co2-emission-preview-item.component.html',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    SharedTranslocoModule,
    MatIconModule,
    InfoButtonComponent,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatDividerModule,
    MeaningfulRoundPipe,
    CalculationResultPreviewEmissionsTooltipComponent,
  ],
})
export class Co2EmissionPreviewItemComponent {
  @Input() styleClass: string | undefined;

  public _item: CalculationResultPreviewItem;
  public isSingleItem = false;
  public readonly yearlyEmissionUsageInHours = 8766;

  @Input() set item(item: CalculationResultPreviewItem) {
    this._item = item;
    this.isSingleItem = item.values.length === 1;
  }
}
